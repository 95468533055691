<template>
  <div class="time-kedu">
      <div class="half-line"></div>
      <div></div>
      <div></div>
      <div></div>
      <div class="long-line" :class="{ active: timeMenu == true }"></div>
      <div></div>
      <div></div>
      <div></div>
      <div class="half-line"></div>
  </div>
</template>
<script>
export default {
  name: "Timeline",
  data() {
    return {};
  },
  props: {
    timeMenu: {
      type: Boolean,
      default: false
    },
    
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
  .time-kedu{
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
    >div{
      width: 1px;
      height: 1.6vh;
      background: #BFBFBF;
    }
    >div.half-line{
      width: .5px;
    }
    >div.long-line{
      height: 3.6vh;
    }
    >div.long-line.active{
      background: #1F4692;
    }
  }
</style>
